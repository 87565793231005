<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Reset Password</div>
      <div class="mb-4">
        Password reset link will be sent to your email address.
      </div>
      <a-form
        ref="registerForm"
        :model="forgotForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="email">
          <a-input v-model:value="forgotForm.email" placeholder="Email Address" />
        </a-form-item>
        <a-button type="primary" class="text-center w-100" html-type="submit" :loading="loading">
          <strong>Reset my password</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="vb__utils__link">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Go to Sign in
      </router-link>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as actions from '../../../store/actions.type'

export default {
  name: 'VbForgotPassword',
  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
    }
    const forgotForm = reactive({
      email: '',
    })

    const handleFinish = async (values) => {
      loading.value = true
      await store.dispatch(`user/${actions.UsersAction.RESET_USER_PASSWORD}`, {
        email: values.email,
      })
      router.push('/auth/login')
      loading.value = false
    }
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }

    return {
      rules,
      forgotForm,
      handleFinish,
      handleFinishFailed,
      loading,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
</style>
